import { Box, Typography } from '@mui/material'
import React from 'react'

interface Props {
  message: string
}

const MessageBox: React.FC<Props> = ({ message }) => {
  return (
      <Box
      display="flex"
      height="100%"
      width="100%"
      alignItems="center"
      justifyContent="center"
    >
        <Typography variant="subtitle2" color="primary">
              {message}
          </Typography>
    </Box>
  )
}

export default MessageBox
