import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import { ThemeProvider, createTheme } from '@mui/material'
import { AppContextProvider } from './context/AppContext'
import reportWebVitals from './reportWebVitals'

const customTheme = createTheme({
  palette: {
    primary: {
      main: '#1976d2',
      contrastText: 'white',
    },
  },
})

// const defaultTheme = createTheme();
ReactDOM.render(
    <React.StrictMode>
      <ThemeProvider theme={customTheme}>
        <AppContextProvider />
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
