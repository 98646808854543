import { User } from 'firebase/auth'
import React, {
  FunctionComponent,
  createContext,
  useEffect,
  useState,
} from 'react'
import MessageBox from '../modules/common/components/MessageBox'
import { PORTFOLIOS_ACCESS_TOKEN } from '../utils/constants'
import { auth } from '../utils/firebase'

const App = React.lazy(() => import('../App'))

interface IAppContext {
  user: User | undefined
  userLoading: boolean
  userToken: string | undefined
}

const initContext: IAppContext = {
  user: undefined,
  userLoading: false,
  userToken: undefined,
}

export const AppContext = createContext<IAppContext>(initContext)

export const AppContextProvider: FunctionComponent = ({ children }) => {
  const [token, setToken] = useState('')
  const [currentUser, setCurrentUser] = useState<any>(null)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    auth.onAuthStateChanged(async (user: any) => {
      setLoading(true)

      setCurrentUser(user)
      if (user) {
        const idToken = await user.getIdToken()
        console.log(idToken)
        localStorage.setItem(PORTFOLIOS_ACCESS_TOKEN, idToken)
        setToken(idToken)
      } else setToken('')

      setLoading(false)
    })
  }, [])

  const context: IAppContext = {
    user: currentUser,
    userLoading: loading,
    userToken: token,
  }
  return (
      <AppContext.Provider value={context}>
        {children}

        <React.Suspense fallback={<MessageBox message="loading..." />}>
          <App idToken={token} />
      </React.Suspense>
    </AppContext.Provider>
  )
}
